import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				КодМайстер
			</title>
			<meta name={"description"} content={"Ми допоможемо вам опанувати сучасні технології та навички, необхідні для успішної кар'єри у сфері IT. "} />
			<meta property={"og:title"} content={"КодМайстер"} />
			<meta property={"og:description"} content={"Ми допоможемо вам опанувати сучасні технології та навички, необхідні для успішної кар'єри у сфері IT. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/pace-oregon-state-html-css-codin-1024x576.jpg?v=2024-06-18T12:51:15.611Z) bottom/cover"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Ласкаво просимо до "КодМайстер"
					</Text>
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Ми - провідна веб-школа, що спеціалізується на навчанні програмуванню.
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" border-color="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline5"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Ми допоможемо вам опанувати сучасні технології та навички, необхідні для успішної кар'єри у сфері IT. Наші курси розроблені професіоналами та адаптовані для різних рівнів підготовки - від початківців до досвідчених програмістів.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/profbase-programmer-4.webp?v=2024-06-18T12:51:15.626Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					height="300px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/profbase-programmer-4.webp?v=2024-06-18T12%3A51%3A15.626Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/profbase-programmer-4.webp?v=2024-06-18T12%3A51%3A15.626Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/profbase-programmer-4.webp?v=2024-06-18T12%3A51%3A15.626Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/profbase-programmer-4.webp?v=2024-06-18T12%3A51%3A15.626Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/profbase-programmer-4.webp?v=2024-06-18T12%3A51%3A15.626Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/profbase-programmer-4.webp?v=2024-06-18T12%3A51%3A15.626Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/profbase-programmer-4.webp?v=2024-06-18T12%3A51%3A15.626Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline5"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					"КодМайстер" пропонує широкий вибір курсів, включаючи веб-розробку, мобільну розробку, машинне навчання, аналіз даних та багато іншого.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Наші курси створені з урахуванням актуальних тенденцій ринку та вимог роботодавців. Всі програми включають теоретичні знання та практичні завдання, що допоможуть вам закріпити матеріал та набути необхідного досвіду.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 0 0">
			<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Переваги навчання в "КодМайстер"
			</Text>
		</Section>
		<Section padding="0px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Гнучкий графік навчання
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Наші курси доступні у різних форматах - очно, онлайн та у змішаному режимі. Це дозволяє студентам вибрати зручний графік навчання, що поєднується з їхнім життям та роботою.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Практична спрямованість
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Наші курси включають багато практичних завдань та реальних проектів, що допомагає студентам закріпити теоретичні знання та набути необхідного досвіду для роботи в IT-сфері.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Підтримка після завершення курсу
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Ми надаємо підтримку нашим випускникам навіть після завершення курсу. Допомагаємо з працевлаштуванням, надаємо консультації та відповідаємо на питання, що виникають у процесі роботи.
				</Text>
			</Box>
		</Section>
		<Section padding="0px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Професійні інструктори
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Наші інструктори - це досвідчені професіонали з реальним досвідом роботи в IT-індустрії. Вони діляться практичними знаннями та навичками, допомагаючи студентам досягти високих результатів.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					<Strong>
						Сучасні навчальні матеріали
					</Strong>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Ми використовуємо актуальні та оновлені навчальні матеріали, що відповідають останнім тенденціям та вимогам ринку. Це дозволяє студентам отримувати знання, які одразу можна застосувати на практиці.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Індивідуальний підхід
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Ми забезпечуємо індивідуальний підхід до кожного студента, враховуючи їхні потреби та рівень підготовки. Наші інструктори надають персональні консультації та допомагають вирішувати складні питання.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline5"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Ми гарантуємо якість нашого навчання. Якщо ви не задоволені курсом, ми готові повернути вам кошти протягом перших двох тижнів навчання.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Ми також надаємо постійну підтримку нашим студентам, допомагаючи їм вирішувати будь-які питання та проблеми, що виникають у процесі навчання. Наші випускники успішно працюють у провідних IT-компаніях по всьому світу.
				</Text>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/programming-language_1160-100052401-orig-001_i.jpg?v=2024-06-18T12:51:15.602Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					З моменту заснування "КодМайстер" ми допомогли тисячам студентів опанувати програмування та знайти роботу в IT-індустрії.{" "}
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="70%"
				>
					Забронюйте свій корт у PrimeServe сьогодні та дізнайтеся, чому ми є улюбленим місцем для тенісистів, яким потрібна якість і стабільність.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});